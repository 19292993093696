import React, { Component } from 'react';

// Images
import ImgPlayVideo from '../../assets/img/discover/img-play-video.png'
import ImgOvatoWallet from '../../assets/img/discover/img-ovato-wallet.png'
import ImgGovato from '../../assets/img/discover/img-govato.png'
import { OVATO_URL } from '../../constants/defaultValues';
import ModalVideoManager from '../modal/ModalVideoManager';
import DiscoverVideo from '../video/DiscoverVideo';

class Section1 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <>
        <section class="home-1 new-home-2 new-home-2-new discover-1">
          <div class="container">
            <div class="row flex align-center home-1-row-2">
              <div class="col-sm-7 home-1-right-col home-1-right-col-new home-1-right-col-new-2">

                <div className='v2-mb-0 img-wrap discover-logo-img-wrap'>
                    <a href='/'><img src={ImgGovato} width={95} /></a>
                </div>

                <h1 class="poppins text-center v2-font-39 v2-m-font-22 bold white-text v2-m-mb-0"><strong class="double-underline double-underline-heading">Discover</strong></h1>
                
                <ul class="ul-checklist white-text discover-checklist">
                    <li>Great compensation package! </li>
                    <li>Tools and support! </li>
                    <li>Assets that are appreciable! </li>
                    <li>Royalties forever! </li>
                    <li>Multiple strains of income!</li>
                </ul>

                <div className='text-center img-wrap discover-play-img'>
                    {/* <a href='https://www.youtube.com/watch?v=cekUUvu8JIM' target="_blank"><img src={ImgPlayVideo} /></a> */}
                    <DiscoverVideo videoURL={'https://www.youtube.com/watch?v=cekUUvu8JIM'} videoThumb={ImgPlayVideo} />
                </div>
                
              </div>
              
              <div class="col-sm-5 relative">
                <div class="img-wrap new-banner-img">
                  <a href={'#'}><img src={ImgOvatoWallet} /></a>
                </div>
              </div>
              
            </div>
          </div>
          {/* <!-- end class container --> */}
        </section>

        <section className='discover-2'>
            <div className='container'>
                <div className='row'>
                    <div className='col'>
                        <div class="btn-wrap home-3-btn-wrap">
                            <a href='/get-started' class="site-btn site-btn-2 site-btn-subtext  signup-v3-btn">
                                Join Now
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
  }
}

export default Section1;